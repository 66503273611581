import { createStore } from "vuex";
import authModule from "./modules/auth";
import dashboardModule from "./modules/dashboard";
import appointmentModule from "./modules/appointment";
import announcementModule from "./modules/announcement";
import rbiModule from "./modules/rbi";

export default createStore({
  modules: {
    auth: authModule,
    dashboard: dashboardModule,
    appointment: appointmentModule,
    announcement: announcementModule,
    rbi: rbiModule
  },
  strict: true, // process.env.NODE_ENV !== 'production',
  state: {
    isSidebarMinimized: false,
    userName: "Vasili S",
  },
  getters: {},
  actions: {},
  mutations: {
    updateSidebarCollapsedState(state, isSidebarMinimized) {
      state.isSidebarMinimized = isSidebarMinimized;
    },
    changeUserName(state, newUserName) {
      state.userName = newUserName;
    },
  },
});
