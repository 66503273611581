import api from "@/services/dashboard/dashboardApi";

export default {
  getAgeGroupList() {
    return api.get("/age-group").then(response => response.data);
  },
  getAgeGroup(id: string) {
    return api.get(`/age-group/${id}`).then(response => response.data);
  },
  getAppointmentList() {
    return api.get("/appointment/").then(response => response.data);
  },
  getAppointment(id: string) {
    return api.get(`/appointment/${id}`).then(response => response.data);
  },
  getAverageList() {
    return api.get("/average").then(response => response.data);
  },
  getAverage(id: string ) {
    return api.get(`/average/${id}`).then(response => response.data);
  },
  getCitizenshipList() {
    return api.get("/citizenship").then(response => response.data);
  },
  getCitizenship(id: string) {
    return api.get(`/citizenship/${id}`).then(response => response.data);
  },
  getCivilStatusList() {
    return api.get("/civil-status").then(response => response.data);
  },
  getCivilStatus(id: string) {
    return api.get(`/civil-status/${id}`).then(response => response.data);
  },
  getRefresh() {
    return api.get("/refresh").then(response => response.data);
  },
  refreshStats() {
    return api.post("/refresh/", { duration: null }).then(response => response.data);
  },
  getSocialClassList() {
    return api.get("/social-class").then(response => response.data);
  },
  getSocialClass(id: string) {
    return api.get(`/social-class/${id}`).then(response => response.data);
  },
  getTotalList() {
    return api.get("/totals").then(response => response.data);
  },
  getTotal(id: string) {
    return api.get(`/total/${id}`).then(response => response.data);
  },
  getUserLoginList() {
    return api.get("/user-login").then(response => response.data);
  },
  getUserLogin(id: string) {
    return api.get(`/user-login/${id}`).then(response => response.data);
  },
  getUserSignUpList() {
    return api.get("/user-signup").then(response => response.data);
  },
  getUserSignUp(id: string) {
    return api.get(`/user-signup/${id}`).then(response => response.data);
  },
  getUserLoginMonthlyList() {
    return api.get("/user-login-by-month").then(response => response.data);
  },
  getUserSignUpMonthlyList() {
    return api.get("/user-signup-by-month").then(response => response.data);
  },
};
