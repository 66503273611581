export default [
  {
    name: "add",
    to: "add",
  },
  {
    name: "arrow_drop_down",
    to: "arrow_drop_down",
  },
  {
    name: "arrow_drop_up",
    to: "arrow_drop_up",
  },
  {
    name: "calendar_today",
    to: "calendar_today",
  },
  {
    name: "check",
    to: "check",
  },
  {
    name: "chevron_left",
    to: "chevron_left",
  },
  {
    name: "chevron_right",
    to: "chevron_right",
  },
  {
    name: "done",
    to: "done",
  },
  {
    name: "entypo_user",
    to: "entypo-entypo-user",
  },
  {
    name: "expand_less",
    to: "expand_less",
  },
  {
    name: "expand_more",
    to: "expand_more",
  },
  {
    name: "angle_down",
    to: "fa4-angle-down",
  },
  {
    name: "angle_up",
    to: "fa4-angle-up",
  },
  {
    name: "bell",
    to: "fa4-bell",
  },
  {
    name: "bell_slash",
    to: "fa4-bell-slash",
  },
  {
    name: "bug",
    to: "fa4-bug",
  },
  {
    name: "caret_down",
    to: "fa4-caret-down",
  },
  {
    name: "caret_up",
    to: "fa4-caret-up",
  },
  {
    name: "check_circle",
    to: "fa4-check_circle",
  },
  {
    name: "chevron-left",
    to: "fa4-chevron-left",
  },
  {
    name: "chevron-right",
    to: "fa4-chevron-right",
  },
  {
    name: "circle",
    to: "fa4-circle",
  },
  {
    name: "close",
    to: "fa4-close",
  },
  {
    name: "cogs",
    to: "fa4-cogs",
  },
  {
    name: "envelope",
    to: "fa4-envelope",
  },
  {
    name: "eye",
    to: "fa4-eye",
  },
  {
    name: "gear",
    to: "fa4-gear",
  },
  {
    name: "google",
    to: "fa4-google",
  },
  {
    name: "map",
    to: "fa4-map",
  },
  {
    name: "map_marker",
    to: "fa4-map-marker",
  },
  {
    name: "mars",
    to: "fa4-mars",
  },
  {
    name: "minus",
    to: "fa4-minus",
  },
  {
    name: "music",
    to: "fa4-music",
  },
  {
    name: "pencil",
    to: "fa4-pencil",
  },
  {
    name: "print",
    to: "fa4-print",
  },
  {
    name: "refresh",
    to: "fa4-refresh",
  },
  {
    name: "search",
    to: "fa4-search",
  },
  {
    name: "trash",
    to: "fa4-trash",
  },
  {
    name: "user",
    to: "fa4-user",
  },
  {
    name: "venus",
    to: "fa4-venus",
  },
  {
    name: "volume_off",
    to: "fa4-volume-off",
  },
  {
    name: "volume_up",
    to: "fa4-volume-up",
  },
  {
    name: "first_page",
    to: "first_page",
  },
  {
    name: "github",
    to: "fa4-github",
  },
  {
    name: "glyphicon_envelope",
    to: "glyphicon-glyphicon-envelope",
  },
  {
    name: "glyphicon_pencil",
    to: "glyphicon-glyphicon-pencil",
  },
  {
    name: "highlight_off",
    to: "highlight_off",
  },
  {
    name: "iconicstroke_info",
    to: "iconicstroke-iconicstroke_info",
  },
  {
    name: "ion_arrow_down",
    to: "ion-arrow-down",
  },
  {
    name: "ion_arrow_forward",
    to: "ion-arrow-forward",
  },
  {
    name: "ion_ios_alarm",
    to: "ion-ios-alarm",
  },
  {
    name: "ion_md_close",
    to: "ion-md-close",
  },
  {
    name: "ion_md_grid",
    to: "ion-md-grid",
  },
  {
    name: "ion_md_help",
    to: "ion-md-help",
  },
  {
    name: "ion_md_image",
    to: "ion-md-image",
  },
  {
    name: "ion_md_images",
    to: "ion-md-images",
  },
  {
    name: "ion_md_key",
    to: "ion-md-key",
  },
  {
    name: "ion_md_list",
    to: "ion-md-list",
  },
  {
    name: "ion_md_musical_notes",
    to: "ion-md-musical-notes",
  },
  {
    name: "ion_md_star_outline",
    to: "ion-md-star-outline",
  },
  {
    name: "last_page",
    to: "last_page",
  },
  {
    name: "loop",
    to: "loop",
  },
  {
    name: "maki_art_gallery",
    to: "maki-maki-art-gallery",
  },
  {
    name: "remove",
    to: "remove",
  },
  {
    name: "vuestic-iconset-dashboard",
    to: "vuestic-iconset-dashboard",
  },
  {
    name: "vuestic-iconset-files",
    to: "vuestic-iconset-files",
  },
  {
    name: "vuestic-iconset-forms",
    to: "vuestic-iconset-forms",
  },
  {
    name: "vuestic-iconset-maps",
    to: "vuestic-iconset-maps",
  },
  {
    name: "vuestic-iconset-settings",
    to: "vuestic-iconset-settings",
  },
  {
    name: "vuestic-iconset-statistics",
    to: "vuestic-iconset-statistics",
  },
  {
    name: "vuestic-iconset-tables",
    to: "vuestic-iconset-tables",
  },
  {
    name: "vuestic-iconset-ui-elements",
    to: "vuestic-iconset-ui-elements",
  },
  {
    name: "warning",
    to: "warning",
  },
];
